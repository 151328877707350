import React from "react";
import { useEffect } from "react";
import { useState } from "react";

const FormAddOrder = ({ visibility, handleClose, axiosAdd }) => {
  const [vendor, setVendor] = useState("");
  const [resi, setResi] = useState("");
  const [country, setCountry] = useState("");
  const [sender, setSender] = useState("");
  const [senderContact, setSenderContact] = useState("");
  const [recipient, setRecipient] = useState("");
  const [recipientContact, setRecipientContact] = useState("");
  const [recipientAddress, setRecipientAddress] = useState("");
  const [from, setFrom] = useState("");
  const [empty, setEmpty] = useState("");
  if (!visibility) {
    return null;
  }

  function changeResi(value) {
    setResi(value);
  }
  function changeCountry(value) {
    setCountry(value);
  }
  function changeSender(value) {
    setSender(value);
  }
  function changeSenderContact(value) {
    setSenderContact(value);
  }
  function changeRecipient(value) {
    setRecipient(value);
  }
  function changeRecipientContact(value) {
    setRecipientContact(value);
  }
  function changeRecipientAddress(value) {
    setRecipientAddress(value);
  }
  function changeFrom(value) {
    setFrom(value);
  }
  function changeVendor(value) {
    setVendor(value);
    setResi("");
    setCountry("");
    setSender("");
    setSenderContact("");
    setRecipient("");
    setRecipientContact("");
    setRecipientAddress("");
    setFrom("");
    setEmpty("");
  }

  function close() {
    setVendor("");
    setResi("");
    setCountry("");
    setSender("");
    setSenderContact("");
    setRecipient("");
    setRecipientContact("");
    setRecipientAddress("");
    setFrom("");
    setEmpty("");
    handleClose();
  }

  function submitAdd(e) {
    e.preventDefault();
    setEmpty("");

    if (vendor === "tgi") {
      if (!resi) {
        setEmpty("Resi");
      } else {
        setVendor("");
        axiosAdd({
          vendor,
          resi,
        });
      }
    } else {
      if (!resi) {
        setEmpty("Resi");
      } else if (!country) {
        setEmpty("Negara Tujuan");
      } else if (!sender) {
        setEmpty("Nama Pengirim");
      } else if (!senderContact) {
        setEmpty("Telepon Pengirim");
      } else if (!recipient) {
        setEmpty("Nama Penerima");
      } else if (!recipientContact) {
        setEmpty("Telepon Penerima");
      } else if (!recipientAddress) {
        setEmpty("Alamat Penerima");
      } else if (!from) {
        setEmpty("Dikirim Dari");
      } else {
        setVendor("");
        axiosAdd({
          vendor,
          resi,
          sender,
          senderContact,
          recipient,
          recipientContact,
          country,
          recipientAddress,
          from,
        });
      }
    }
  }

  return (
    <div className="fixed inset-0 bg-slate-100 bg-opacity-30 backdrop-blur-sm flex justify-center items-center z-40">
      <form
        className="p-10 rounded-xl border-4 border-slate-300 bg-white"
        onSubmit={(e) => submitAdd(e)}
      >
        <div className="text-center">
          <h1 className="text-3xl font-semibold">Form Order Baru</h1>
        </div>
        <div className="my-3">
          <p
            className="text-gray-700 text-sm font-bold text-center"
            for="vendor"
          >
            {vendor ? "Vendor" : <span className="text-pink-500">Vendor</span>}
          </p>
          <select
            name=""
            id="from"
            className="block mx-auto px-3 py-2"
            onChange={(e) => changeVendor(e.target.value)}
          >
            <option selected disabled>
              Pilih Vendor
            </option>
            <option value="tgi">TGI</option>
            <option value="manual">manual</option>
          </select>
        </div>
        {vendor === "tgi" ? (
          <div className="flex gap-5 my-2">
            <div className="">
              <label
                className="text-gray-700 text-sm font-bold text-center"
                for="resi"
              >
                {resi ? "Resi" : <span className="text-pink-500">Resi</span>}
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="resi"
                type="text"
                placeholder="Masukkan resi"
                onChange={(e) => changeResi(e.target.value)}
              />
            </div>
          </div>
        ) : (
          ""
        )}
        {vendor === "manual" ? (
          <>
            {" "}
            <div className="flex gap-5">
              <div className="">
                <label
                  className="text-gray-700 text-sm font-bold text-center"
                  for="resi"
                >
                  {resi ? "Resi" : <span className="text-pink-500">Resi</span>}
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="resi"
                  type="text"
                  placeholder="Masukkan resi"
                  onChange={(e) => changeResi(e.target.value)}
                />
              </div>
              <div className="">
                <label
                  className="text-gray-700 text-sm font-bold text-center"
                  for="tujuan"
                >
                  {country ? (
                    "Negara Tujuan "
                  ) : (
                    <span className="text-pink-500">Negara Tujuan </span>
                  )}
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="tujuan"
                  type="text"
                  placeholder="Masukkan negara tujuan"
                  onChange={(e) => changeCountry(e.target.value)}
                />
              </div>
            </div>
            <div className="flex gap-5">
              <div className="">
                <label
                  className="text-gray-700 text-sm font-bold text-center"
                  for="sender"
                >
                  {sender ? (
                    "Nama Pengirim "
                  ) : (
                    <span className="text-pink-500">Nama Pengirim </span>
                  )}
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="sender"
                  type="text"
                  placeholder="Masukkan nama pengirim"
                  onChange={(e) => changeSender(e.target.value)}
                />
              </div>
              <div className="">
                <label
                  className="text-gray-700 text-sm font-bold text-center"
                  for="senderContact"
                >
                  {senderContact ? (
                    "Telepon Pengirim"
                  ) : (
                    <span className="text-pink-500">Telepon Pengirim</span>
                  )}
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="senderContact"
                  type="text"
                  placeholder="Masukkan telepon pengirim"
                  onChange={(e) => changeSenderContact(e.target.value)}
                />
              </div>
            </div>
            <div className="flex gap-5">
              <div className="">
                <label
                  className="text-gray-700 text-sm font-bold text-center"
                  for="recipient"
                >
                  {recipient ? (
                    "Nama Penerima"
                  ) : (
                    <span className="text-pink-500">Nama Penerima</span>
                  )}
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="recipient"
                  type="text"
                  placeholder="Masukkan nama penerima"
                  onChange={(e) => changeRecipient(e.target.value)}
                />
              </div>
              <div className="">
                <label
                  className="text-gray-700 text-sm font-bold text-center"
                  for="recipientContact"
                >
                  {recipientContact ? (
                    "Telepon Penerima"
                  ) : (
                    <span className="text-pink-500">Telepon Penerima</span>
                  )}
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="recipientContact"
                  type="text"
                  placeholder="Masukkan telepon penerima"
                  onChange={(e) => changeRecipientContact(e.target.value)}
                />
              </div>
            </div>
            <div className="">
              <label
                className="text-gray-700 text-sm font-bold text-center"
                for="address"
              >
                {recipientAddress ? (
                  "Alamat Penerima"
                ) : (
                  <span className="text-pink-500">Alamat Penerima</span>
                )}
              </label>
              <textarea
                id="address"
                rows="2"
                className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Masukkan alamat penerima..."
                onChange={(e) => changeRecipientAddress(e.target.value)}
              />
            </div>
            <div className="my-3">
              <p
                className="text-gray-700 text-sm font-bold text-center"
                for="password"
              >
                {from ? (
                  "Dikirim dari"
                ) : (
                  <span className="text-pink-500">Dikirim dari</span>
                )}
              </p>
              <select
                name=""
                id="from"
                className="block mx-auto px-3 py-2"
                onChange={(e) => changeFrom(e.target.value)}
              >
                <option selected disabled>
                  Pilih kotal asal
                </option>
                <option value="Bandung">Bandung</option>
                <option value="Jakarta">Jakarta</option>
              </select>
            </div>
          </>
        ) : (
          ""
        )}

        <div className="flex items-center justify-center gap-3">
          <button
            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-5 rounded-full focus:outline-none focus:shadow-outline"
            type="button"
            onClick={() => close()}
          >
            Close
          </button>
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-5 rounded-full focus:outline-none focus:shadow-outline"
            type="submit"
          >
            Submit
          </button>
        </div>
        <div className="text-center my-3">
          {empty ? `Kolom ${empty} tidak boleh kosong` : null}
        </div>
      </form>
    </div>
  );
};

// Resi : SGR1234 .
// Dikirim Dari : jakarta
// Negara Tujuan : south korea .
// Alamat Pengiriman : Lorem, ipsum dolor sit amet consectetur adipisicing elit. Debitis, eius?
// Sender : Lorem .
// Telepon Sender : 0812000000 .
// Penerima : Lorem .
// Telepon Penerima : 084512341234 .
// Status : ongoing

export default FormAddOrder;
